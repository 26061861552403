import { Outlet } from '@remix-run/react';
import { Link } from 'react-router-dom';
import { Button } from '~/components/shadcn/components/ui/button';

export const ErrorBoundary = () => {
  return (
    <div className={ 'w-screen h-screen bg-primary flex flex-col items-center justify-center' }>
      <div className={ 'flex flex-col bg-primary rounded-lg border border-neutral-300 p-8 items-center justify-center shadow-md' }>
        <div className={ 'text-2xl font-bold mb-2' }>{ 500 }</div>
        <div className={ 'text-xl font-semibold mb-8' }>{ 'Internal Server Error' }</div>
        <div className={ 'max-w-sm text-center mb-8 whitespace-pre-line' }>
          {'We regret to inform you that our system is currently unable to handle your request due to an internal error.\r\n\r\nPlease try to refresh the page or go back to the dashboard.'}
        </div>
        <div className={ 'flex flex-row gap-4' }>
          <Link to={ '/' }>
            <Button
              variant='secondary'
              color={ 'neutral' }>
              Return to Dashboard
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default function EmptyLayout() {
  return (
    <Outlet />
  )
}